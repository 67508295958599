body.sk-theme {
    .sk-button {
        @include b4nd-button();
        @include sk-sppx_font-size(20);
        background-color: #BC9E88;
        font-weight: 600;
        letter-spacing: 0.2em;
        @include min {
            max-width: 480px;
            width: 480px;
        }
        @include max {
            max-width: 320px;
        }
    }
    .sk-button-white {
        @include b4nd-button('white');
        @include sk-sppx_font-size(20);
        color: #BC9E88;
        font-weight: 600;
        letter-spacing: 0.2em;
        @include min {
            max-width: 480px;
            width: 480px;
        }
        @include max {
            max-width: 320px;
        }
    }
    .sk-top {
        @include min {
            .sec0 {
                position: relative;
                background-color: #dfe8ef;
                &__inner {
                    position: relative;
                    height: 100vh;
                    height: 100dvh;
                    min-height: 50vw;
                    max-height: 175vw;
                    overflow: hidden;
                }
                &__logo-wrap {
                    width: 424px;
                    width: 31.176471vw;
                    max-width: 30vw;
                    position: absolute;
                    top: calc(50% - 56px);
                    left: calc(50% + 9.4vw);
                    z-index: 1;
                }
                &__kv-wrap {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: calc(100vh / 1.30882353);
                    width: calc(100dvh / 1.30882353);
                    min-width: 50vw;
                    max-width: 74vw;
                    height: 100vh;
                    height: 100dvh;
                    min-height: calc(50vw * 1.30882353);
                    max-height: 175vw;
                    /*
                    width: 50vw;
                    max-width: calc(100vh / 1.30882353);
                    min-width: calc(600px /1.30882353);
                    height: calc(50vw * 1.30882353);
                    min-height: 700px;
                    max-height: 100vh;
                    */
                }
                &__kv {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }
            .sec1,.sec6 {
                background-color: #D9D0C9;
                text-align: center;
                &__inner {
                    position: relative;
                    max-width: 960px;
                    margin: 0 auto 0;
                    padding-top: 38px;
                    padding-bottom: 30px;
                }
                &__about-wrap {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 659px;
                }
                &__text-wrap {
                    position: relative;
                }
                &__text {
                    letter-spacing: 0.08em;
                    line-height: 2.4;
                    font-size: 20px;
                    font-weight: 300;
                    color: #605B57;
                }
                &__button-wrap {
                    position: relative;
                    padding-top: 33px;
                }
                &__button {
                    margin-bottom: 32px;
                }
            }
            .sec2 {
                background-color: #F4EFEC;
                &__inner {
                    padding-top: 51px;
                    padding-bottom: 80px;
                    max-width: 960px;
                    margin: 0 auto 0;
                    display: flex;
                    justify-content: space-between;
                }
                &__block {
                    width: 50%;
                    position: relative;
                    margin: 0 auto 0;
                    &-bg-text {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 79px;
                        &.letter {
                            left: auto;
                            right: 0;
                        }
                    }
                    &-img {
                        position: relative;
                        width: 278px;
                        margin: 0 auto 0;
                    }
                    &-text {
                        padding: 8px 20px 8px;
                        position: absolute;
                        bottom: 34px;
                        left: 52px;
                        display: inline-block;
                        background-color: #BC9E88;
                        font-size: 16px;
                        font-weight: 300;
                        letter-spacing: 0.08em;
                        line-height: 32px;
                        color: #fff;
                        &.letter {
                            left: auto;
                            right: 52px;
                        }
                    }
                }
            }
            .sec3 {
                background-color: #BEA18B;
                &__inner {
                    padding: 48px 20px 72px;
                    max-width: 720px;
                    margin: 0 auto 0;
                }
                &__head-text {
                    color: #E5DDD6;
                    font-size: 40px;
                    font-weight: 300;
                    letter-spacing: 0.16em;
                    line-height: 1;
                    text-align: center;
                }
                &__sub-text {
                    margin-top: 16px;
                    color: #E5DDD6;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.16em;
                    line-height: 1;
                    font-family: $font-family-hiraginokaku;
                    text-align: center;
                }
                &__price {
                    margin-top: 40px;
                    color: #fff;
                    font-size: 24px;
                    letter-spacing: 0.08em;
                    font-family: $font-family-hiraginokaku;
                    text-align: center;
                    line-height: 1;
                    font-weight: 500;
                    .price {
                        display: inline-block;
                        margin-right: 5px;
                        margin-left: 5px;
                        font-size: 56px;
                        letter-spacing: 0;
                        font-weight: 600;
                    }
                    .tax {
                        display: inline-block;
                        letter-spacing: 0;
                    }
                }
                &__annotation {
                    color: #E5DDD6;
                    font-family: $font-family-hiraginokaku;
                    margin-top: 30px;
                    font-size: 12px;
                    letter-spacing: 0.08em;
                    line-height: 1.6;
                    font-weight: 500;
                }
            }
            .sec4 {
                background-color: #D9D0C9;
                &__inner {
                    padding-top: 72px;
                    padding-bottom: 72px;
                    position: relative;
                    overflow: hidden;
                    max-width: 960px;
                    margin: 0 auto 0;
                }
                &__bg-text {
                    position: absolute;
                    top: 0;
                    left: 26px;
                    width: 94.6875%;
                }
                &__slide-wrap {
                    margin: 0 auto 0;
                    width: 95.3125%;
                }
                .swiper {
                    overflow: visible !important;
                }
                &__slide {
                    padding: 0 7.5px 0;
                }
                &__slide-inner {
                    padding-top: 30px;
                    padding: 33px 18px 20px;
                    background-color: #fff;
                    border-radius: 32px;
                }
                &__slide-num {
                    position: relative;
                    font-size: 40px;
                    color: #605B57;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 1;
                    &::before,&::after {
                        content: '';
                        display: block;
                        height: 1px;
                        width: 69px;
                        background-color: #000;
                        position: absolute;
                        bottom: 16px;
                        right: 0;
                    }
                    &::after {
                        width: 14px;
                        transform-origin: bottom right;
                        transform: rotate(35deg);
                    }
                    &.last::before, &.last::after {
                        display: none;
                    }
                }
                &__slide-text {
                    min-height: 64px;
                    margin-top: 14px;
                    color: #605B57;
                    font-weight: 300;
                    font-size: 13px;
                    letter-spacing: 0;
                    line-height: 2;
                }
                &__slide-img{
                    margin-right: auto;
                    margin-left: auto;
                    margin-top: 14px;
                }
                &__annotation {
                    margin-top: 24px;
                    font-size: 10px;
                    color: #605B57;
                    font-weight: 300;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 1;
                }
            }
            .sec5 {
                background-color: #F4EFEC;
                &__inner {
                    padding: 48px 0 16px;
                    max-width: 720px;
                    margin: 0 auto 0;
                }
                &__head-text {
                    font-size: 40px;
                    font-weight: 300;
                    letter-spacing: 0.16em;
                    line-height: 1;
                    text-align: center;
                    color: #605B57;
                }
                &__faq-wrap {
                    margin-top: 32px;
                }
                &__faq-item {
                    margin-bottom: 32px;
                }
                &__question,&__answer,&__annotation {
                    position: relative;
                    padding-left: 24px;
                    font-size: 14px;
                    line-height: 1.714;
                    color: #605B57;
                    letter-spacing: 0;
                    font-weight: 600;
                }
                &__question {
                    &::before {
                        content: 'Q';
                        font-size: 20px;
                        line-height: 1;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
                &__answer {
                    margin-top: 12px;
                    font-weight: 300;
                    &::before {
                        content: 'A';
                        font-size: 20px;
                        line-height: 1;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
                &__annotation {
                    margin-top: 12px;
                    font-size: 10px;
                    font-weight: 300;
                }
            }
            .sec6 {
                &__inner {
                    @include sk-sppx_padding(21,1);
                    @include sk-sppx_padding(21,3);
                }
            }
        }
        @include max {
            .sec0 {
                position: relative;
                background-color: #dfe8ef;
                &__inner {
                    position: relative;
                    height: 100vh;
                    height: 100dvh;
                    overflow: hidden;
                    @media screen and (max-width: $sk-break-point) {
                    }
                }
                &__logo-wrap {
                    position: relative;
                    z-index: 1;
                    padding-top: 70px;
                    display: flex;
                    align-items: center;
                    height: calc(100vh - (110vw * 1.33398821) );
                    height: calc(100dvh - (110vw * 1.33398821) );
                    min-height: 147px;
                    @media screen and (max-width: $sk-break-point) {
                        padding-top: sk-sppx(70);
                        height: calc(100vh - sk-sppx(679));
                        height: calc(100dvh - sk-sppx(679));
                        min-height: sk-sppx(147);
                    }
                }
                &__logo {
                    width: 298px;
                    margin: 0 auto 0;
                    @media screen and (max-width: $sk-break-point) {
                        width: sk-sppx(298);
                    }
                }
                &__kv-wrap {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    min-height: calc((110vw * 1.33398821) + 80px);
                    @media screen and (max-width: $sk-break-point) {
                        min-height: calc(sk-sppx(679) + sk-sppx(80));
                    }
                }
                &__kv {
                    position: absolute;
                    left: calc( (100vw - 509px) / 2 );
                    width: 509px;
                    bottom: 0;
                    left: -5vw;
                    width: 110vw;
                    @media screen and (max-width: $sk-break-point) {
                        left: calc( (100vw - sk-sppx(509)) / 2 );
                        width: sk-sppx(509);
                    }
                }
                &__scroll-wrap {
                    position: absolute;
                    right: 12px;
                    bottom: 43px;
                    z-index: 1;
                    width: 12px;
                    &::after {
                        content: '';
                        position : absolute;
                        bottom: -80px;
                        left: 5.5px;
                        display: block;
                        width: 1px;
                        height: 84px;
                        background-color: #000;
                    }
                }
            }
            .sec1,.sec6 {
                background-color: #D9D0C9;
                text-align: center;
                &__inner {
                    position: relative;
                    @include sk-sppx_padding(53);
                    @include sk-sppx_padding(32,3);
                    max-width: 540px;
                    margin: 0 auto 0;
                }
                &__about-wrap {
                    position: absolute;
                    top: 5.5px;
                    left: 0;
                    width: 59px;
                    @media screen and (max-width: $sk-break-point) {
                        top: sk-sppx(5.5);
                        width: sk-sppx(59);
                    }
                }
                &__text-wrap {
                    position: relative;
                }
                &__text {
                    letter-spacing: 0.08em;
                    line-height: 40px;
                    font-size: 16px;
                    font-weight: 300;
                    color: #605B57;
                    @media screen and (max-width: $sk-break-point) {
                        line-height: sk-sppx(40);
                        font-size: sk-sppx(16);
                    }
                }
                &__button-wrap {
                    position: relative;
                    @include sk-sppx_padding(35);
                }
                &__button {
                    margin-bottom: 32px;
                }
            }
            .sec2 {
                background-color: #F4EFEC;

                &__inner {
                    @include sk-sppx_padding(48);
                    @include sk-sppx_padding(116,3);
                    max-width: 540px;
                    margin: 0 auto 0;
                }

                &__block {
                    position: relative;
                    margin: 0 auto 0;
                    &.letter {
                        @include sk-sppx_margin(105);
                    }
                    &-bg-text {
                        position: absolute;
                        bottom: -34px;
                        right: 0;
                        @include sk-sppx_width(79);
                        &.letter {
                            right: auto;
                            left: 0;
                        }
                    }
                    &-img {
                        position: relative;
                        @include sk-sppx_width(278);
                        margin: 0 auto 0;
                    }
                    &-text {
                        padding: 8px 20px 8px;
                        position: absolute;
                        bottom: -34px;
                        left: 0;
                        display: inline-block;
                        background-color: #BC9E88;
                        @include sk-sppx_font-size(16);
                        font-weight: 300;
                        letter-spacing: 0.08em;
                        line-height: 32px;
                        color: #fff;
                        @media screen and (max-width: $sk-break-point) {
                            line-height: sk-sppx(32);
                        }
                        &.letter {
                            left: auto;
                            right: 0;
                        }
                    }
                }
            }
            .sec3 {
                background-color: #BEA18B;
                &__inner {
                    @include sk-sppx_padding(48,1);
                    @include sk-sppx_padding(48,3);
                    @include sk-sppx_padding-lr(20);
                    max-width: 540px;
                    margin: 0 auto 0;
                }
                &__head-text {
                    color: #E5DDD6;
                    @include sk-sppx_font-size(40);
                    font-weight: 300;
                    letter-spacing: 0.16em;
                    line-height: 1;
                    text-align: center;
                }
                &__sub-text {
                    @include sk-sppx_margin(16,1);
                    color: #E5DDD6;
                    @include sk-sppx_font-size(16);
                    font-weight: 500;
                    letter-spacing: 0.16em;
                    line-height: 1;
                    font-family: $font-family-hiraginokaku;
                    text-align: center;
                }
                &__price {
                    @include sk-sppx_margin(30,1);
                    color: #fff;
                    @include sk-sppx_font-size(24);
                    letter-spacing: 0.08em;
                    font-family: $font-family-hiraginokaku;
                    text-align: center;
                    line-height: 1;
                    font-weight: 500;
                    .price {
                        display: inline-block;
                        @include sk-sppx_margin-lr(5);
                        @include sk-sppx_font-size(56);
                        letter-spacing: 0;
                        font-weight: 600;
                    }
                    .tax {
                        display: inline-block;
                        letter-spacing: 0;
                    }
                }
                &__annotation {
                    color: #E5DDD6;
                    font-family: $font-family-hiraginokaku;
                    @include sk-sppx_margin(30,1);
                    @include sk-sppx_font-size(10);
                    letter-spacing: 0.08em;
                    line-height: 1.6;
                    font-weight: 500;
                }
            }
            .sec4 {
                background-color: #D9D0C9;
                &__inner {
                    @include sk-sppx_padding(48);
                    @include sk-sppx_padding(48,3);
                    position: relative;
                    overflow: hidden;
                }
                &__bg-text {
                    @include sk-sppx_margin(10,1);
                    position: absolute;
                    top: 0;
                    left: calc( (100vw - sk-sppx(415)) /2 );
                    width: sk-sppx(415);
                }
                &__slide-wrap {
                    max-width: 390px;
                    margin: 0 auto 0;
                }
                .swiper {
                    overflow: visible !important;
                }
                &__slide {
                    @include sk-sppx_padding-lr(8);
                }
                &__slide-inner {
                    @include sk-sppx_padding(33,1);
                    @include sk-sppx_padding-lr(20);
                    @include sk-sppx_padding(21,3);
                    background-color: #fff;
                    border-radius: 32px;
                    @media screen and (max-width: $sk-break-point) {
                        border-radius: sk-sppx(32);
                        min-height: sk-sppx(64);
                    }
                }
                &__slide-num {
                    position: relative;
                    @include sk-sppx_font-size(40);
                    color: #605B57;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 1;
                    &::before,&::after {
                        content: '';
                        display: block;
                        height: 1px;
                        @include sk-sppx_width(69);
                        background-color: #000;
                        position: absolute;
                        bottom: 16px;
                        right: 0;
                        @media screen and (max-width: $sk-break-point) {
                            bottom: sk-sppx(16);
                        }
                    }
                    &::after {
                        @include sk-sppx_width(14);
                        transform-origin: bottom right;
                        transform: rotate(35deg);
                    }
                    &.last::before, &.last::after {
                        display: none;
                    }
                }
                &__slide-text {
                    min-height: 64px;
                    @include sk-sppx_margin(14,1);
                    color: #605B57;
                    font-weight: 300;
                    @include sk-sppx_font-size(14);
                    letter-spacing: 0;
                    line-height: 2;
                    @media screen and (max-width: $sk-break-point) {
                        min-height: sk-sppx(64);
                    }
                }
                &__slide-img{
                    max-width: 60%;
                    margin-right: auto;
                    margin-left: auto;
                    @include sk-sppx_margin(14,1);
                }
                &__annotation {
                    @include sk-sppx_margin(24);
                    font-size: 10px;
                    color: #605B57;
                    font-weight: 300;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 1;
                }
            }
            .sec5 {
                background-color: #F4EFEC;
                &__inner {
                    @include sk-sppx_padding(48,1);
                    @include sk-sppx_padding(16,3);
                    @include sk-sppx_padding-lr(20);
                    max-width: 540px;
                    margin: 0 auto 0;
                }
                &__head-text {
                    @include sk-sppx_font-size(40);
                    font-weight: 300;
                    letter-spacing: 0.16em;
                    line-height: 1;
                    text-align: center;
                    color: #605B57;
                }
                &__faq-wrap {
                    @include sk-sppx_margin(32,1);
                }
                &__faq-item {
                    @include sk-sppx_margin(32,3);
                }
                &__question,&__answer,&__annotation {
                    position: relative;
                    @include sk-sppx_padding(24,4);
                    @include sk-sppx_font-size(14);
                    line-height: 1.714;
                    color: #605B57;
                    letter-spacing: 0;
                    font-weight: 600;
                }
                &__question {
                    &::before {
                        content: 'Q';
                        @include sk-sppx_font-size(20);
                        line-height: 1;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
                &__answer {
                    @include sk-sppx_margin(12);
                    font-weight: 300;
                    &::before {
                        content: 'A';
                        @include sk-sppx_font-size(20);
                        line-height: 1;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
                &__annotation {
                    @include sk-sppx_margin(12);
                    font-size: 10px;
                    font-weight: 300;
                }
            }
            .sec6 {
                &__inner {
                    @include sk-sppx_padding(21,1);
                    @include sk-sppx_padding(21,3);
                }
            }
        }
    }

    .form-message {
    }

    .login {
        &__register-button {
            @include b4nd-button('border');
            font-family: 'ヒラギノ明朝 ProN';
            border: solid 2px #BC9E88;
            color: #BC9E88;
        }
    }

    .payment {
        &__price {
            background-color: #BC9E88;
        }
    }

    .subscription {
        &__list {
            border-top: 2px solid #BC9E88; 
            border-bottom: 2px solid #BC9E88; 
        }
        &__link {
            &::after {
                border-top: 2px solid #BC9E88;
                border-right: 2px solid #BC9E88;
            }
        }
    }

    .sub-detail {
        &__price {
            background-color: #BC9E88;
        }
    }

    .register-profile {
    }
}
