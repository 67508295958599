.b4nd {
    &__contents {
        //background-color: #ddd;
    }
    &__contents-inner {
        background-color: #fff;
        //max-width: 375px;
        max-width: 450px;
        margin: 0 auto 0;
        min-height: calc($window-height - $footer-height) ;
        @include sppx_font-size(16);
        @include sppx_padding-lr(20);
        @include sppx_padding(80,1);
        @include sppx_padding(40,3);
    }

    &__back-wrap {
        @include sppx_margin(30,1);
        text-align: center;
    }
    &__back {
        text-decoration: underline;
        @include sppx_font-size(16);
    }
    &__install-button {
        display: block;
        max-width: 320px;
        @include sppx_margin(40,1);
        margin-right: auto;
        margin-left: auto;
    }

    &__head-title {
        text-align: center;
        @include sppx_margin(30,3);
    }
    &__head-text {
        @include sppx_margin(30,3);
        @include sppx_font-size(14);
        &.align-center {
            text-align: center;
        }
    }

}

.form-message {
    @include sppx_margin(30,3);
    @include sppx_padding-lr(10);
    @include sppx_padding(5,1);
    @include sppx_padding(5,3);
    background-color: #dc3545;
    border-radius: 5px;
    &__item {
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        color: #fff;
        line-height: 1.5;
        @include sppx_font-size(13);
    }
}


.login {
    &__forgot-password-wrap {
        @include sppx_margin(30,1);
        text-align: center;
    }
    &__forgot-password {
        text-decoration: underline;
        @include sppx_font-size(13);
    }
    &__register-wrap  {
        @include sppx_margin(60,1);
        text-align: center;
    }
    &__register-button {
        @include b4nd-button('border');
    }
}

.payment {
    &__form-head {
        text-align: center;
        @include sppx_padding(30,3);
    }
    &__artist-name {
        @include sppx_margin(20,1);
        @include sppx_font-size(20);
    }
    &__price-head {
        @include sppx_margin(20,1);
    }
    &__price {
        display: inline-block;
        color: #fff;
        background-color: #44CCCC;
        border-radius: 5px;
        @include sppx_margin(5,1);
        @include sppx_padding-lr(10);
        @include sppx_padding(5,1);
        @include sppx_padding(5,3);
    }
}

.subscription {
    &__subscriptions {
        @include sppx_margin(50,3);
    }
    &__head {
        font-weight: bold;
        @include sppx_margin(10,3);
    }
    &__list {
        border-top: 2px solid #44CCCC; 
        border-bottom: 2px solid #44CCCC; 
    }
    &__item {
        border-bottom: 1px solid #ccc;
        &:last-child {
            border-bottom: none;
        }
    }
    &__link {
        position: relative;
        @include sppx_padding(10,1);
        @include sppx_padding(10,3);
        display: flex;
        align-items: center;
        &::after {
            content: "";
            margin: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 20px;
            width: 10px;
            height: 10px;
            border-top: 2px solid #44CCCC;
            border-right: 2px solid #44CCCC;
            transform: rotate(45deg);
        }
    }
    &__img-wrap {
        border-radius: 100%;
        overflow: hidden;
        width: 20%;
    }
    &__text-wrap {
        @include sppx_margin(10,4);
    }
    &__date {
        @include sppx_font-size(14);
    }
}

.sub-detail {
    &__artist-img-wrap {
        width: 33%;
        border-radius: 100%;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
    }
    &__artist-name {
        text-align: center;
        @include sppx_font-size(20);
        @include sppx_margin(10,1);
    }

    &__body {
        text-align: center;
    }
    &__plan-text {
        @include sppx_margin(20,1);
    }
    &__price {
        display: inline-block;
        color: #fff;
        background-color: #44CCCC;
        border-radius: 5px;
        @include sppx_margin(5,1);
        @include sppx_padding-lr(10);
        @include sppx_padding(5,1);
        @include sppx_padding(5,3);
    }
    &__update-date {
        @include sppx_margin(20,1);
        font-weight: bold;
    }
    &__payment-method {
        @include sppx_margin(10,1);
    }
    &__button {
        @include sppx_margin(30,1);
    }
    &__cancel-button-wrap {
        @include sppx_margin(30,1);
    }
    &__cancel-modal-inner {
        background-color: #fff;
        border-radius: 10px;
        @include sppx_padding-lr(10);
        @include sppx_padding(30,1);
        @include sppx_padding(30,3);
    }
    &__cancel-modal {
        position: fixed;
        width: 100%;
        height: 100vh;
        background-color: rgba(0,0,0,0.6);
        top: 0;
        left: 0;
        z-index: 2000;
    }
    &__cancel-modal-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        @include sppx_padding-lr(10);
    }
    &__cancel-modal-body {
        @include sppx_margin(20,1);
        @include sppx_font-size(14);
    }
    &__resubscribe {
        text-align: left;
        @include sppx_margin(20,1);
        @include sppx_font-size(13);
    }
}

.register-profile {
    &__confirm-modal {
        position: fixed;
        width: 100%;
        height: 100vh;
        background-color: rgba(0,0,0,0.6);
        top: 0;
        left: 0;
        z-index: 2000;
    }
    &__confirm-modal-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        @include sppx_padding-lr(10);
    }
    &__confirm-modal-inner {
        background-color: #fff;
        border-radius: 10px;
        max-width: 95vw;
        max-height: 90vh;
        max-height: calc(var(--vh,1vh) * 90);
        width: 450px;
        margin-right: auto;
        margin-left: auto;
        @include sppx_padding-lr(20);
        @include sppx_padding(30,1);
        @include sppx_padding(30,3);
        overflow-y: auto;
    }
    &__confirm-modal-head {
        text-align: center;
    }
    &__confirm-modal-body {
        @include sppx_margin(30,1);
        @include sppx_font-size(14);
    }
    &__confirm-modal-row {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        @include sppx_margin(15,3);
    }
    &__confirm-modal-column-name {
        display: inline-block;
        @include sppx_width(110);
    }
    &__confirm-modal-column-value {
        @include sppx_width(205);
    }
}
