body.sk-theme {
    .form {

        input[type="text"],  
        input[type="mail"], 
        input[type="password"], 
        input[type="date"], 
        select {
            &:focus {
                border: 1px solid #BC9E88;
                outline: #BC9E88;
                box-shadow: 0px 0px 4px #BC9E88;
            }
        }
        input[type="radio"] {
            &:checked {
                background-color: #BC9E88; 
            }
        }
        input[type="checkbox"] {
            &:checked {
                background-color: #BC9E88;
            }
        }
        button {
            @include b4nd-button;
            background-color: #BC9E88;
            font-family: 'ヒラギノ明朝 ProN';
            &.border {
                @include b4nd-button('border');
                font-family: 'ヒラギノ明朝 ProN';
                border: solid 2px #BC9E88;
                color: #BC9E88;
            }
            &:disabled {
                background-color: #ccc;
                box-shadow: none;
                transition: none;
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}
