@use "sass:math";

//ブレークポイント
$sk-break-point: 390px;

//画面サイズに応じたpx数に変換
@function sk-sppx($value) {
	@return math.div($value , 390) * 100vw;
}

@mixin sk-sppx_width($value) {
    width: calc(1px * $value);
    @media screen and (max-width: $sk-break-point) {
        width: sk-sppx($value);
    }
}
@mixin sk-sppx_font-size($value) {
    font-size: calc(1px * $value);
    @media screen and (max-width: $sk-break-point) {
        font-size: sk-sppx($value);
    }
}
@mixin sk-sppx_padding-lr($value) {
    padding-left: calc(1px * $value);
    padding-right: calc(1px * $value);
    @media screen and (max-width: $sk-break-point) {
        padding-left: sk-sppx($value);
        padding-right: sk-sppx($value);
    }
}
@mixin sk-sppx_padding($value, $type: 1) {
    @if $type == 1 {
        padding-top: calc(1px * $value);
        @media screen and (max-width: $sk-break-point) {
            padding-top: sk-sppx($value);
        }
    }
    @else if $type == 2 {
        padding-right: calc(1px * $value);
        @media screen and (max-width: $sk-break-point) {
            padding-right: sk-sppx($value);
        }
    }
    @else if $type == 3 {
        padding-bottom: calc(1px * $value);
        @media screen and (max-width: $sk-break-point) {
            padding-bottom: sk-sppx($value);
        }
    }
    @else if $type == 4 {
        padding-left: calc(1px * $value);
        @media screen and (max-width: $sk-break-point) {
            padding-left: sk-sppx($value);
        }
    }
}

@mixin sk-sppx_margin-lr($value) {
    margin-left: calc(1px * $value);
    margin-right: calc(1px * $value);
    @media screen and (max-width: $sk-break-point) {
        margin-left: sk-sppx($value);
        margin-right: sk-sppx($value);
    }
}
@mixin sk-sppx_margin($value, $type: 1) {
    @if $type == 1 {
        margin-top: calc(1px * $value);
        @media screen and (max-width: $sk-break-point) {
            margin-top: sk-sppx($value);
        }
    }
    @else if $type == 2 {
        margin-right: calc(1px * $value);
        @media screen and (max-width: $sk-break-point) {
            margin-right: sk-sppx($value);
        }
    }
    @else if $type == 3 {
        margin-bottom: calc(1px * $value);
        @media screen and (max-width: $sk-break-point) {
            margin-bottom: sk-sppx($value);
        }
    }
    @else if $type == 4 {
        margin-left: calc(1px * $value);
        @media screen and (max-width: $sk-break-point) {
            margin-left: sk-sppx($value);
        }
    }
}

@mixin sk-sppx_margin_important($value, $type: 1) {
    @if $type == 1 {
        margin-top: calc(1px * $value) !important;
        @media screen and (max-width: $sk-break-point) {
            margin-top: sk-sppx($value) !important;
        }
    }
    @else if $type == 2 {
        margin-right: calc(1px * $value) !important;
        @media screen and (max-width: $sk-break-point) {
            margin-right: sk-sppx($value) !important;
        }
    }
    @else if $type == 3 {
        margin-bottom: calc(1px * $value) !important;
        @media screen and (max-width: $sk-break-point) {
            margin-bottom: sk-sppx($value) !important;
        }
    }
    @else if $type == 4 {
        margin-left: calc(1px * $value) !important;
        @media screen and (max-width: $sk-break-point) {
            margin-left: sk-sppx($value) !important;
        }
    }
}

body.sk-theme {
.title-balloon {
    background-color: #BC9E88;
    &::after {
        border-top: 24px solid #BC9E88;
        @include max {
            border-top: 16px solid #BC9E88;
        }
    }
    &.is-white {
        color: #BC9E88; 
    }
}

.b4nd-button {
    @include b4nd-button();
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: #BC9E88;
}
.b4nd-button.is-border {
    @include b4nd-button('border');
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: solid 2px #BC9E88;
    color: #BC9E88;
}

.sk-margin-top-l {
    @include sk-sppx_margin_important(30,1);
}
.sk-margin-bottom-l {
    @include sk-sppx_margin_important(30,3);
}
.sk-margin-top-m {
    @include sk-sppx_margin_important(15,1);
}
.sk-margin-bottom-m {
    @include sk-sppx_margin_important(15,3);
}

.app-install-link {
    display: block;
    width: 240px;
    max-width: 80vw;
    margin: 0 auto 32px;
}


}
