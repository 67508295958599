body.sk-theme {
    .menu {
        background-color: #5c554f;
        &__link {
            background-color: transparent;
            &:hover {
                background-color: #BC9E88;
            }
        }
    }
}