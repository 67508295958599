.menu {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: -270px;
    @include sppx_width(250);
    height: 100vh;
    background-color: #343a40;
    color: #fff;
    padding-top: 80px;
    transition: all 0.3s ease;
    @include sppx_font-size(16);
    &.open {
        right: 0;
    }

    &__list {
        @include sppx_padding-lr(10);
        @include sppx_padding(10,1);
        @include sppx_padding(10,3);
    }
    &__link {
        display: block;
        width: 100%;
        transition: all 0.3s ease !important;
        line-height: 2.5;
        border-radius: 5px;
        @include sppx_padding-lr(20);
        background-color: #343a40;
        &:hover {
            background-color: #44CCCC;
            opacity: 1;
        }
    }
}