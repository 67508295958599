@charset "utf-8";

$content-max: 1206px;
$font-family-notsansjp: 'Noto Sans JP', sans-serif;
$font-family-hiraginomincho: 'ヒラギノ明朝 ProN', serif;
$font-family-hiraginokaku: 'ヒラギノ角ゴシック', sans-serif;

$breakpoint: (
  sp: "screen and (max-width: 768px)",
);

@mixin mq($bp) {
  @media #{map-get($breakpoint, $bp)} {
    @content;
  }
}

@mixin max980($breakpoint: 980px) {
  $breakpoint: $breakpoint - 1;
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}

@mixin max($breakpoint: 768px) {
  $breakpoint: $breakpoint - 1;
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}

@mixin min($breakpoint: 768px) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}

@import "./util.scss";
@import "./util-sk.scss";

body {
  overflow-x: hidden;
  background-color: #F4F6F8;
  font-family: $font-family-notsansjp;
  letter-spacing: 0;
  line-height: 1.8;
  &.sk-theme {
    font-family: $font-family-hiraginomincho;
  }
}

img {
  width: 100%;
  height: auto;
}

a {
  opacity: 1;
  &:hover {
    opacity: 0.7;
    transition: ease-in 0.3s opacity;
  }
}

body.top-page {
  font-size: 16px;
  @include max {
    //font-size: 4.102564103vw;
  }
  @include sppx_font-size(16);
  line-height: 1.6;

  .keyVisual {
    display: flex;
    flex-flow: column;
    background-image: url(../img/b4nd_top_background.jpg);
    @include max {
      height: 100vh;
      height: 100dvh;
      background-image: url('../img/b4nd_top_background_sp.jpg');
      min-height: unset;

      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
    }
  }
  .keyVisualFooter {
    display: flex;
    gap: 0 20px;
    @include max {
      width: 76.428205128%;
      margin: 0 auto;
    }
  }
  .btn-googleplay {
    width: 216px;
  }
  .btn-applestore {
    width: 174px;
  }
  .keyVisualMain {
    margin-bottom: 40px;
  }
  .keyVisualMain__title {
    width: 459.19px;
    margin-bottom: 35px;
    @include max {
      width: 76.428205128%;
      margin: 0 auto;
    }
  }
  .keyVisualMain__text {
    width: 389px;
    @include max {
      width: 69.230769231%;
      margin: 0 auto;
    }
  }
  .sectionConcept__contentTitle {
    @include max {
      font-size: 4.615384615vw;
    }
  }
  .sectionConcept__title {
    margin-bottom: 55px;
    padding-left: 5%;
    padding-right: 5%;
  }
  .sectionConcept__titleimg {
    width: 530px;
    margin: 0 auto 52px;
    display: block;
    @include max {
      width: 85.641025641%;
      margin-bottom: 20px;
    }
  }
  .sectionConcept__brand {
    max-width: 890px;
    margin: 0 auto;
    display: flex;
    @include max {
      max-width: 82.564102564%;
    }
  }
  .sectionConcept__content {
    @include max980 {
      max-width: inherit;
      padding-left: 5%;
      padding-right: 5%;
    }
    @include max {
      max-width: 82.564102564%;
    }
  }
  .sectionConcept__contentMessage {
    width: 530px;
    margin: 0 auto 120px;
    @include max980 {
      width: auto;
    }
    @include max {
      font-size: 3.58974359vw;
      margin-bottom: 64px;
      br.for-pc {
        display: none;
      }
    }
  }
  .sectionConcept__titleSub {
    @include max {
      width: 85.641025641%;
      margin: 0 auto;
      line-height: 1.8;
      br {
          display: none;
      }
    }
  }
  .sectionConcept__contentMessage span {
    color: #37BECB;
  }
  .sectionConcept__content_slide {
    @include max {
      max-width: inherit;
    }
  }
  .swiper__container {
    position: relative;
  }
  .features {
    margin: 0 auto;
    @include max {
      gap: 40px 60px;
    }
  }
  .featurePoint__header {
    max-width: 922px;
    margin: 0 auto 85px;
    gap: 0 40px;
    @include max {
      margin-bottom: 50px;
    }
  }
  .feature__preview {
    margin-bottom: 56px;
    @include max {
      margin-bottom: 27px;
    }
  }

  .featureAction__name {
    font-size: 32px;
    position: relative;
    display: inline-block;
    @include max {
      font-size: 4.615384615vw;
    }
    &.letter-icon::after {
      content: '';
      display: block;
      position: absolute;
      background-image: url("../img/letter-icon.png");
      background-repeat: no-repeat;
      background-size: contain;
      top: 11px;
      right: -55px;
      width: 38px;
      height: 28px;
      @include max {
        width: 5.992307692vw;
        right: -8vw;
        top: 1.9vw;
      }
    }
    &.talk-icon::after {
      content: '';
      display: block;
      position: absolute;
      background-image: url("../img/talk-icon.png");
      background-repeat: no-repeat;
      background-size: contain;
      top: 5px;
      right: -55px;
      width: 44px;
      height: 36px;
      @include max {
        width: 6.930769231vw;
        right: -8vw;
        top: 0.8vw;
      }
    }
    &.gift-icon::after {
      content: '';
      display: block;
      position: absolute;
      background-image: url("../img/gift-icon.png");
      background-repeat: no-repeat;
      background-size: contain;
      top: 11px;
      right: -55px;
      width: 45px;
      height: 30px;
      @include max {
        width: 8.282051282vw;
        right: -9.5vw;
        top: 1.3vw;
      }
    }
  }
  .featurePoint__name {
    border-color: #BFEAEB;
    border: 2px solid;
    color: #37BECB;
    font-size: 48px;
    width: 240px;
    height: 240px;
    font-weight: 500;
    @include max {
      width: 30.256410256vw;
      height: 30.256410256vw;
      font-size: 7.179487179vw;
    }
  }
  .featureAction {
    flex:1;
    margin-bottom: 0;
    @include max {
      width: 85.641025641%;
      margin: 0 auto;
    }
  }
  .featureAction__description {
    font-size: 24px;
    @include max {
      font-size: 4.102564103vw;
    }
  }
  .featureSection {
    padding-bottom: 30px;
    padding-left: 5%;
    padding-right: 5%;
    @include max {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 50px;
    }
  }
  .feature__footnote {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    @include max {
      width: 85.641025641%;
      margin: 0 auto;
      font-size: 4.102564103vw;
      text-align: left;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .featureAction__footnote {
    font-size: 12px;
    color: #707070;
    padding-top: 20px;
    
  }
  .swiper__container .swiper {
    @include max980 {
      width: auto;
    }
    @include max {
      width: 72.307692308%;
    }
  }
  .swiper__container .swiper-button-prev {
    @include max980 {
      left: -5%;
    }
    @include max {
      left: 3%;
    }
  }
  .swiper__container .swiper-button-next {
    @include max980 {
      right: -5%;
    }
    @include max {
      right: 3%;
    }
  }
  .sectionNotification {
    padding-top: 0;
    @include max {
      padding-bottom: 60px;
    }
  }
  .notification {
    
  }
  .sectionNotification__title {
    font-size: 24px;
    text-align: center;
    font-weight: 400;
    color: #007D89;
    margin-bottom: 39px;
    @include max {
      font-size: 4.615384615vw;
      margin-bottom: 24px;
    }
  }
  .notifications {
    padding: 29px 27px;
    border-radius: 8px;
    @include max {
      width: 85.641025641%;
      margin: 0 auto;
      padding: 26px 14px;
    }
  }
  .notification__date {
    font-weight: 400;
    font-size: 14px;
    color: #007D89;
    padding-bottom: 5px;
    @include max {
      font-size: 3.58974359vw;
      padding-bottom: 2px;
    }
  }
  
}

body.artist-detail {

  .mv {
    height: 100vh;
    min-height: 900px;
    //background-image: url('../img/mv_background_pc.jpg');
    //background-size: cover;
    //background-repeat: no-repeat;
    background-color: #75D8DD;

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    @include max {
      height: 100vh;
      height: 100dvh;
      //background-image: url('../img/mv_background_sp.jpg');
      min-height: 660px;

      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
    }
    &__body {
      @include max {
        min-height: 550px;
      }
    }
    &__logo_wrap {
      max-width: 459.19px;
      margin: 0 auto;
      padding-bottom: 32px;
      @include max {
        padding-bottom: 9%;
        max-width: 62%;
      }
    }
    &__circle {
      border: 3px solid #fff;
      max-width: 320px;
      margin: 0 auto 20px;
      border-radius: 50%;
      overflow: hidden;
      @include max {
        max-width: 61.538461538%;
        margin: 0 auto 4.102564103%;
      }
      &__img {
      }
    }
    &__name {
      font-size: 24px;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      padding-bottom: 35px;
      @include max {
        font-size: 4.615384615vw;
      }
    }
  }

  .common {
    &__btn_wrap {
      max-width: 380px;
      display: block;
      margin: 0 auto;
      @include max {
        max-width: 85.641025641%;
      }
    }
  }

  .member {
    padding-top: 64px;
    padding-bottom: 64px;
    background-color: #75D8DD;
    @include max {
      padding-top: 16.41025641%;
      padding-bottom: 16.41025641%;
    }
    &__body {
      display: flex;
      flex-flow: wrap;
      width: 80%;
      max-width: inherit;
      justify-content: center;
      gap: 0 5px;

      margin: 0 auto;
      @include max980 {
        flex-direction: column;
        max-width: 380px;
      }
      @include max {
        max-width: inherit;
        max-width: 380px;
      }
    }
    &__list {
      padding-bottom: 85px;
      &:last-of-type {
        padding-bottom: 0;
      }
    }
    &__circle {
      border: 3px solid #fff;
      max-width: 320px;
      margin: 0 auto 20px;
      border-radius: 50%;
      overflow: hidden;
      @include max {
        max-width: 61.538461538%;
        margin: 0 auto 4.102564103%;
      }
    }
    &__name {
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      padding-bottom: 22px;
    }
    &__personal-page-link-wrap {
      width: 380px;
      text-align: center;
      @include max {
        width: 100%;
      }
    }
    &__personal-page-link {
      max-width: 80%;
    }
  }

  .contents {
    //max-width: $content-max;
    margin: 0 auto;
  }
  .about {
    padding: 80px 0;
    background-color: #75D8DD;
    @include max {
      padding-top: sppx(40);
      padding-bottom: sppx(40);
      padding-left: sppx(23);
      padding-right: sppx(23);
    }
    &__title {
      margin-bottom: 50px;
      @include max {
        margin-bottom: sppx(36);
      }
    }
    &__head-desc {
      color: #fff;
      max-width: 700px;
      margin: 0 auto;
      text-align: center;
      font-size: 24px;
      padding-bottom: 41px;
      @include max {
        text-align: left;
        font-size: 4.102564103vw;
        padding-bottom: 8.205128205%;
      }
    }
    &__flex {
      display: flex;
      justify-content: space-evenly;
      max-width: 920px;
      margin: 0 auto;
      @include max {
        display: block;
      }
    }
    &__column {
      max-width: 282px;
      margin: 0 auto;
      @include max {
        max-width: sppx(282);
        margin-bottom: sppx(40);
      }
    }
    &__column-title {
      color: #fff;
      font-family: "Zen Maru Gothic", serif;
      font-size: 34px;
      font-weight: bold;
      text-align: center;
      @include max {
        font-size: sppx(34);
      }
    }
    &__column-text {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 20px;
      @include max {
        font-size: sppx(16);
        margin-bottom: sppx(20);
      }
    }

  }
  .plan {
    padding: 80px 0;
    background-color: #ECFBFC;
    @include max {
      padding-top: sppx(40);
      padding-bottom: sppx(40);
      padding-left: sppx(23);
      padding-right: sppx(23);
    }
    &__title-wrap {
      width: 200px;
      margin: 0 auto;
      @include max {
        width: sppx(118);
      }
    }
    &__price {
      text-align: center;
      color: #007D89;
      font-weight: bold;
      font-size: 32px;
      @include max {
        font-size: sppx(24);
      }
      .price {
        display: inline-block;
        font-size: 96px;
        margin-left: 15px;
        margin-right: 15px;
        @include max {
          font-size: sppx(72);
          margin-left: sppx(10);
          margin-right: sppx(10);
        }
      }
      .tax {
        display: inline-block;
        font-size: 24px;
        @include max {
          font-size: sppx(16);
        }
      }
    }
    &__text {
      text-align: center;
      color: #007D89;
      font-weight: bold;
      font-size: 24px;
      @include max {
        font-size: sppx(16);
      }
    }
    &__annotation {
      max-width: 920px;
      margin: 0 auto;
      margin-top: 40px;
      color: #707070;
      font-size: 16px;
      @include max {
        margin-top: sppx(26);
        font-size: sppx(10);
      }
    }
  }
  .talk {
    padding: 80px 0;
    background-color: #fff;
    @include max {
      padding-top: sppx(40);
      padding-bottom: sppx(40);
    }
    &__title {
      margin-bottom: 80px;
      @include max {
        margin-bottom: sppx(50);
      }
    }
    &__slide_wrap {
      position: relative;

      max-width: 920px;
      margin: 0 auto;

      @include max {
        padding-left: 0;
        padding-right: 0;
      }
      .swiper_wrapper {
      }
      .swiper {
        @include max {
          //width: sppx(271);
          width: 58%;
        }
      }
      .swiper-button-prev {
        display: block;
        content: url('../img/arrow-left.png');
        width: 16.91px;
        height: auto;
        left: -1em;
        position: absolute;
        @include min {
          top: calc(50% + 62px);
        }
        @include max980 {
          left: 1em;
        }
        @include max {
          left: 1.5em;
        }
      }
      .swiper-button-next {
        display: block;
        content: url('../img/arrow-right.png');
        width: 16.16px;
        height: auto;
        right: -1em;
        position: absolute;
        @include min {
          top: calc(50% + 62px);
        }
        @include max980 {
          right: 1em;
        }
        @include max {
          right: 1.5em;
        }
      }
    }
    &__slide-num {
      margin: 0 auto 0;
      width: 50px;
      @include max {
        width: sppx(46);
      }
    }
    &__slide-text {
      margin-top: 12px;
      margin-bottom: 12px;
      font-size: 12px;
      min-height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      @include max(920px) {
        font-size: 10px;
      }
      @include max {
        font-size: sppx(11);
        margin-top: sppx(9);
        margin-bottom: sppx(9);
      }
    }
    &__annotation {
      margin-top: 40px;
      color: #707070;
      font-size: 16px;
      text-align: center;
      @include max {
        margin-top: sppx(26);
        font-size: sppx(10);
      }
    }
  }
  .faq {
    padding: 80px 0;
    @include max {
      padding-top: sppx(40);
      padding-bottom: sppx(40);
      padding-left: sppx(23);
      padding-right: sppx(23);
    }
    &__title {
      margin-bottom: 60px;
      @include max {
        margin-bottom: sppx(36);
      }
    }
    &__wrap {
      max-width: 920px;
      margin: 0 auto;
    }
    &__item {
      margin-bottom: 40px;
      @include max {
        margin-bottom: sppx(32);
      }
    }
    &__question,
    &__answer {
      position: relative;
      padding-left: 50px;
      font-size: 16px;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: 'Q';
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #2CBECC;
        color: #fff;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        font-size: 18px;
        font-weight: bold;
      }
      @include max {
        font-size: sppx(14);
        padding-left: sppx(30);
        &::before {
          width: sppx(24);
          height: sppx(24);
          font-size: sppx(16);
        }
      }
    }
    &__answer {
      margin-top: 16px;
      &::before {
        content: 'A';
        background-color: #fff;
        color: #2CBECC;
      }
      @include max {
        margin-top: sppx(10);
      }
    }
    &__annotation {
      margin-top: 8px;
      color: #707070;
      font-size: 14px;
      padding-left: 50px;
      @include max {
        margin-top: sppx(8);
        padding-left: sppx(30);
        font-size: sppx(10);
      }
    }
  }
  .install {
    padding-top: 64px;
    padding-bottom: 64px;
    background-color: #75D8DD;
    @include max {
      padding-top: 16.41025641%;
      padding-bottom: 16.41025641%;
    }
    &__body {
      max-width: 380px;
      margin: 0 auto;
      @include max {
        max-width: inherit;
      }
    }
  }

  .footer {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #D3D3D3;
    @include max {
      padding-top: 16.41025641%;
      padding-bottom: 16.41025641%;
    }
    &__body {
      max-width: 348px;
      margin: 0 auto;
      @include max {
        max-width: inherit;
      }
    }
    &__link {
      display: flex;
      justify-content: space-between;
      padding-bottom: 25px;
      @include max {
        flex-flow: column;
        align-items: center;
      }
      a {
        font-size: 14px;
        color: #707070;
        text-decoration: underline;
        @include max {
          font-size: 3.58974359vw;
        }
      }
      li {
        &:nth-of-type(1) {
          padding-bottom: 4.102564103%;
        }
      }
    }
    &__copyright {
      font-size: 10px;
      color: #707070;
      text-align: center;
        @include max {
          font-size: 2.564102564vw;
        }
    }
  }
}

// 単体ページ用 
body.page {
  font-size: 16px;
  line-height: 1.6;
  @include max {
    font-size: 4.102564103vw;
  }
  main {
    max-width: 1000px;
    margin: 0 auto;
  }
  h1 {
    text-align: center;
    font-size: 24px;
    padding: 70px 0 30px;
    @include max {
      font-size: 5.128205128vw;
    }
  }

  .page-to-top {
    text-align: center;
    padding-bottom: 80px;
    @include max {
      padding-bottom: 50px;
    }
    a {
      text-decoration: underline;
      transition: 0.3s ease opacity;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.commercial__body {
  tr {
    display: block;
    padding-bottom: 20px;
    &:last-of-type {
      padding-bottom: 0;
    }
  }
  th {
    display: block;
    padding-bottom: 3px;
  }
  table {
    display: block;
    padding-bottom: 80px;
    padding-left: 5%;
    padding-right: 5%;
    @include max {
      padding-bottom: 50px;
    }
  }
}

.policy__body {
  .page-description {
    padding-bottom: 20px;
    padding-left: 5%;
    padding-right: 5%;
  }
  .policy__content {
    padding-bottom: 80px;
    padding-left: 5%;
    padding-right: 5%;
    @include max {
      padding-bottom: 50px;
    }
  }
  .policy__data {
    padding-bottom: 20px;
    &:last-of-type {
      padding-bottom: 0;
    }
    h3 {
      font-weight: bold;
      display: block;
      padding-bottom: 5px;
    }
    p {
      padding-bottom: 5px;
    }

    h5 {
      display: block;
      padding-bottom: 5px;
    }
    ol {

    }
    li {
      padding-bottom: 5px;
    }
    ol ol {
      display: block;
      padding-left: 5px;
    }

    .span-block {
      display: block;
      padding-bottom: 5px;
    }
  }
}
.by-laws {
  padding-top: 40px;
  padding-bottom: 20px;
}

.content-end {
  text-align: right;
}

.siteFooter {
  @include max {
    padding: 67px 0;
  }
}
body.sk-theme {
  .siteFooter {
    background-color: #5C554F;
    color: #fff;
  }
}
.pageLinks {
  flex-flow: row;
  gap: 0 20px;
  @include max {
    flex-flow: column;
    gap: 8px 0;
  }
  a {
    text-decoration: underline;
    @include sppx_font-size(16);
  }
}
.siteFooter__copyWrite {
    @include sppx_font-size(12);
}



@import "module/_header.scss";
@import "module/_header-sk.scss";

@import "module/_menu.scss";
@import "module/_menu-sk.scss";

@import "module/_form.scss";
@import "module/_form-sk.scss";

@import "page/b4nd.scss";
@import "page/sk.scss";