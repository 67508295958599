.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1100;
    display: flex;
    justify-content: space-between;

    &__right {
        position: absolute;
        top: 0;
        right: 0;
    }

    &__login-button {
        display: inline-block;
        @include sppx_margin(20,1);
        @include sppx_margin(20,3);
        @include sppx_margin-lr(20);
        @include sppx_font-size(16);
        span {
            @include sppx_margin(4,2);
        }
    }

    &__menu-button {
        position: fixed;
        top: 10px;
        right: 10px;
        width: 50px;
        height: 50px;
        cursor: pointer;
        @include min() {
            transform: scale(1);
            top: 20px;
            right: 20px;
        }
        span {
            display: inline-block;
            transition: all .4s;
            position: absolute;
            left: 14px;
            height: 3px;
            border-radius: 2px;
            background: #fff;
            width: 45%;
            box-shadow: 1.5px 1.5px 4px rgba(0,0,0,0.5);
            &:nth-of-type(1) {
                top: 15px;
            }
            &:nth-of-type(2) {
                top: 23px;
            }
            &:nth-of-type(3) {
                top: 31px;
            }
        }
        &.active {
            span {
                box-shadow: 1.5px 1.5px 4px rgba(0,0,0,0);
                &:nth-of-type(1) {
                    top: 18px;
                    left: 18px;
                    transform: translateY(6px) rotate(-45deg);
                    width: 30%;
                }
                &:nth-of-type(2) {
                    opacity: 0;
                }
                &:nth-of-type(3) {
                    top: 30px;
                    left: 18px;
                    transform: translateY(-6px) rotate(45deg);
                    width: 30%;
                }
            }
        }
    }

}