@use "sass:math";

//フッターの高さ
$footer-height: var(--footer-height,225px);

//画面の高さ
$window-height: calc(var(--vh,1vh) * 100);

//B4NDと連携する部分のページのブレークポイント
$b4nd-break-point: 375px;

//画面サイズに応じたpx数に変換
@function sppx($value) {
	@return math.div($value , 375) * 100vw;
}

@mixin sppx_width($value) {
    width: calc(1px * $value);
    @media screen and (max-width: $b4nd-break-point) {
        width: sppx($value);
    }
}
@mixin sppx_font-size($value) {
    font-size: calc(1px * $value);
    @media screen and (max-width: $b4nd-break-point) {
        font-size: sppx($value);
    }
}
@mixin sppx_padding-lr($value) {
    padding-left: calc(1px * $value);
    padding-right: calc(1px * $value);
    @media screen and (max-width: $b4nd-break-point) {
        padding-left: sppx($value);
        padding-right: sppx($value);
    }
}
@mixin sppx_padding($value, $type: 1) {
    @if $type == 1 {
        padding-top: calc(1px * $value);
        @media screen and (max-width: $b4nd-break-point) {
            padding-top: sppx($value);
        }
    }
    @else if $type == 2 {
        padding-right: calc(1px * $value);
        @media screen and (max-width: $b4nd-break-point) {
            padding-right: sppx($value);
        }
    }
    @else if $type == 3 {
        padding-bottom: calc(1px * $value);
        @media screen and (max-width: $b4nd-break-point) {
            padding-bottom: sppx($value);
        }
    }
    @else if $type == 4 {
        padding-left: calc(1px * $value);
        @media screen and (max-width: $b4nd-break-point) {
            padding-left: sppx($value);
        }
    }
}

@mixin sppx_margin-lr($value) {
    margin-left: calc(1px * $value);
    margin-right: calc(1px * $value);
    @media screen and (max-width: $b4nd-break-point) {
        margin-left: sppx($value);
        margin-right: sppx($value);
    }
}
@mixin sppx_margin($value, $type: 1) {
    @if $type == 1 {
        margin-top: calc(1px * $value);
        @media screen and (max-width: $b4nd-break-point) {
            margin-top: sppx($value);
        }
    }
    @else if $type == 2 {
        margin-right: calc(1px * $value);
        @media screen and (max-width: $b4nd-break-point) {
            margin-right: sppx($value);
        }
    }
    @else if $type == 3 {
        margin-bottom: calc(1px * $value);
        @media screen and (max-width: $b4nd-break-point) {
            margin-bottom: sppx($value);
        }
    }
    @else if $type == 4 {
        margin-left: calc(1px * $value);
        @media screen and (max-width: $b4nd-break-point) {
            margin-left: sppx($value);
        }
    }
}

@mixin sppx_margin_important($value, $type: 1) {
    @if $type == 1 {
        margin-top: calc(1px * $value) !important;
        @media screen and (max-width: $b4nd-break-point) {
            margin-top: sppx($value) !important;
        }
    }
    @else if $type == 2 {
        margin-right: calc(1px * $value) !important;
        @media screen and (max-width: $b4nd-break-point) {
            margin-right: sppx($value) !important;
        }
    }
    @else if $type == 3 {
        margin-bottom: calc(1px * $value) !important;
        @media screen and (max-width: $b4nd-break-point) {
            margin-bottom: sppx($value) !important;
        }
    }
    @else if $type == 4 {
        margin-left: calc(1px * $value) !important;
        @media screen and (max-width: $b4nd-break-point) {
            margin-left: sppx($value) !important;
        }
    }
}

@mixin b4nd-button($type: 'default') {
    display: inline-block;
    border-radius: 100vh;
    background-color: #44CCCC;
    width: 80%;
    max-width: 300px;
    line-height: 50px;
    color: #fff;
    text-align: center;
    transition: opacity 0.3s ease;
    font-weight: bold;
    box-shadow: 1px 3px 5px rgba(0,0,0,0.2);
    @include max($b4nd-break-point) {
        line-height: sppx(50);
    }
    @if $type == 'white' {
        background-color: #fff;
        color: #44CCCC;
    }
    @if $type == 'border' {
        background-color: #fff;
        border: solid 2px #44CCCC;
        color: #44CCCC;
    }
    &:hover {
        opacity: 0.6;
    }
}

.title-balloon {
    position: relative;
    display: block;
    width: 100%;
    max-width: 530px;
    margin: 0 auto ;
    background-color: #2CBECC;
    color: #fff;
    font-size: 24px;
    line-height: 65px;
    text-align: center;
    border-radius: 8px;
    @include max {
        max-width: sppx(334);
        font-size: sppx(18);
        line-height: sppx(50);
    }
    &::after {
        content: '';
        display: block;
        width: 27px;
        height: 48px;
        position: absolute;
        left: calc(50% - 13.5px);
        bottom: -47px;
        border-top: 24px solid #2CBECC;
        border-left: 13.5px solid transparent;
        border-right: 13.5px solid transparent;
        @include max {
            width: 18px;
            height: 32px;
            left: calc(50% - 9px);
            bottom: -31px;
            border-top: 16px solid #2CBECC;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
        }
    }
    &.is-white {
        background-color: #FFF;
        color: #2CBECC;
        &::after {
            border-top: 24px solid #FFF;
            @include max {
                border-top: 16px solid #FFF;
            }
        }
    }
}

.b4nd-button-wrap {
    text-align: center;
}
.b4nd-button {
    @include b4nd-button();
}
.b4nd-button.is-border {
    @include b4nd-button('border');
}

.margin-top-l {
    @include sppx_margin_important(30,1);
}
.margin-bottom-l {
    @include sppx_margin_important(30,3);
}
.margin-top-m {
    @include sppx_margin_important(15,1);
}
.margin-bottom-m {
    @include sppx_margin_important(15,3);
}

.m-sp {
    @include min {
        display: none !important;
    }
}
.m-pc {
    @include max {
        display: none !important;
    }
}


