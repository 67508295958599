.form {
    
    input[type="text"],  
    input[type="mail"], 
    input[type="password"], 
    input[type="date"], 
    select {
        background-color: #fff;
        padding: 3px 10px;
        border: 1px solid #ccc;
        width: 100%;
        transition: all 0.3s;
        &.width-ml {
            width: 80%;
        }
        &.width-m {
            width: 60%;
        }
        &.width-ms {
            width: 40%;
        }
        &.width-s {
            width: 30%;
        }
        &:focus {
            border: 1px solid #44CCCC;
            outline: #44cccc;
            box-shadow: 0px 0px 4px #44cccc;
        }
        &[disabled] {
            background-color: #eee;
        }
    }
    input[type="radio"] {
        margin-top: -1px;
        border-radius: 50%;
        appearance: none;
        padding: 0;
        display: inline-block;
        vertical-align: middle;
        background-origin: border-box;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        flex-shrink: 0;
        height: 1rem;
        width: 1rem;
        color: #2563eb;
        background-color: #fff;
        border-color: #6b7280;
        border-width: 1px;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
        &:checked {
            background-color: #44CCCC;
            border-color: transparent;
            background-size: 100% 100%;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
    select {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
        background-position: right 0.5rem center;
        background-repeat: no-repeat;
        background-size: 1.5em 1.5em;
        padding-right: 2.5rem;
    }
    input[type="checkbox"] {
        position: static;
        margin-top: 0;
        margin-right: 0.3125rem;
        margin-left: 0;
        border-radius: 0.25em;
        border: solid 1px #ccc;
        box-sizing: border-box;
        padding: 0;
        width: 1rem;
        height: 1rem;
        cursor: pointer;
        &:checked {
            background-color: #44CCCC;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
            border-color: transparent;
            background-size: 100% 100%;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
    input.year {
        @include sppx_width(65);
    }
    input.month,input.day {
        @include sppx_width(45);
    }
    button {
        @include b4nd-button;
        &.border {
            @include b4nd-button('border');
        }
        &:disabled {
            background-color: #ccc;
            box-shadow: none;
            transition: none;
            &:hover {
                opacity: 1;
            }
        }
    }

    &__item-wrap {
        @include sppx_margin(20,3);
        &.padding-lr {
            @include sppx_padding-lr(20);
        }
    }
    &__item {
        @include sppx_margin(20,3);
    }
    &__label-wrap {
        @include sppx_margin(4,3);
    }
    &__label {
        @include sppx_font-size(14);
        &.radio {
            line-height: 26px;
        }
        .required,
        .any {
            display: inline-block;
            background-color: #ccc;
            color: #fff;
            border-radius: 100vh;
            @include sppx_margin(8,4);
            @include sppx_padding-lr(8);
            @include sppx_font-size(11);
        }
        .required {
            background-color: red;
        }
        a {
            text-decoration: underline;
        }
    }

    &__input {
        span {
            display: inline-block;
            @include sppx_margin(10,2);
            position: relative;
            top: 3px;
            @include max($b4nd-break-point) {
                top: sppx(3);
            }
        }
    }

    &__submit {
        display: flex;
        justify-content: center;
        @include sppx_margin(50,1);
        button {
            @include sppx_margin-lr(5)
        }
    }

    &__annotation-wrap {
        @include sppx_margin(4,1);
    }
    &__annotation {
        @include sppx_font-size(12);
    }

    &__text-wrap {
        @include sppx_margin(20,1);
    }
    &__text {
        @include sppx_font-size(13);
    }
    &__birthday-clear {
        display: inline-block;
        text-decoration: underline;
        cursor: pointer;
        @include sppx_font-size(13);
        @include sppx_margin(5,4);
        transition: opacity 0.3s;
        &:hover {
            opacity: 0.7;
        }
    }
}
